<template>
  <v-dialog
    v-model="meetingDialog"
    persistent
    width="900"
    @click:outside="$emit('closeDialog')"
  >
    <v-card>
      <v-card-title class="text-h5 grey lighten-2">
        Visio-conférence
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-row>
          <v-col>
            <student-search-autocomplete
              v-if="showSearch"
              @studentSearchSelected="studentSearchSelected"
            ></student-search-autocomplete>
            <v-text-field
              v-model="selectedStudentText"
              :append-icon="icons.mdiPencil"
              readonly
              dense
              outlined
              label="Apprenant"
              @click:append="showSearch = !showSearch"
            >
            </v-text-field>
            <v-divider></v-divider>
          </v-col>
        </v-row>
      </v-card-text>
      <StudentMeetingTimeTable
        v-if="!meetingsData"
        :student="hydrateStudent"
        :meetings="hydrateMeetings"
      >
      </StudentMeetingTimeTable>
      <NewMeetingDialogForm
        v-if="!studentData"
        :meetings="hydrateMeetings"
        :student="hydrateStudent"
        :tuteur="hydrateTuteur"
        @closeDialog="$emit('closeDialog')"
        @setupData="setupData"
        @meetingAdded="meetingAdded"
      >
      </NewMeetingDialogForm>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiPencil } from '@mdi/js'
import StudentMeetingTimeTable from './StudentMeetingTimeTable.vue'
import NewMeetingDialogForm from './NewMeetingDialogForm.vue'
import StudentSearchAutocomplete from './StudentSearchAutocomplete.vue'

export default {
  components: {
    StudentMeetingTimeTable,
    NewMeetingDialogForm,
    StudentSearchAutocomplete,
  },
  props: {
    meetingDialog: {
      type: Boolean,
      default: false,
    },
    student: {
      type: Object,
      default: null,
    },
    meetings: {
      type: Array,
      default: null,
    },
    timeSet: {
      type: Object,
      default: null,
    },
    tuteur: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      search: '',
      showSearch: true,
      loading: false,
      selectedStudent: null,
      selectedMeetings: null,
      selectedStudentText: '',
      icons:
      {
        mdiPencil,
      },
    }
  },
  computed: {
    studentNotProvided() {
      return (this.student == null)
    },
    meetingsNotProvided() {
      return (this.meetings == null)
    },
    tuteurNotProvided() {
      return (this.tuteur == null)
    },
    studentData() {
      return (this.student == null && this.selectedStudent == null)
    },
    meetingsData() {
      return (this.meetings == null && this.selectedMeetings == null)
    },
    hydrateStudent() {
      return this.studentNotProvided ? this.selectedStudent : this.student
    },
    hydrateMeetings() {
      return this.meetingsNotProvided ? this.selectedMeetings : this.meetings
    },
    hydrateTuteur() {
      return this.tuteurNotProvided ? null : this.tuteur
    },

  },
  watch: {
    student() {
      this.fetchData()
    },
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      if (!this.studentNotProvided) {
        Promise.all([this.fetchStudent()])
          .then(values => {
            [this.selectedStudent] = values
            this.students = values
          })
          .catch(err => console.log(err))
          .finally()
      }
    },
    async fetchStudent() {
      const res = await this.$http.get(`${process.env.VUE_APP_API_URL}/students/${this.student.id}`)

      return res.data
    },
    getMeetings(studentID) {
      this.$http.get(`${process.env.VUE_APP_API_URL}/meetings/?students=${studentID}`)
        .then(res => {
          this.selectedMeetings = res.data
        })
        .catch(err => console.log(err))
    },
    meetingAdded(payload) {
      this.$emit('meetingAdded', payload)
    },
    setupData() {
      this.selectedStudent = null
      this.selectedMeetings = null
      this.showSearch = true
      this.selectedStudentText = ''
    },
    studentSearchSelected(val) {
      this.showSearch = false
      this.selectedStudent = val
      this.selectedStudentText = `${val.prenom} ${val.nom} - ${val.formation.nom}`
      this.getMeetings(val.id)
    },
  },

}
</script>

<style>

</style>
