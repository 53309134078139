<template>
  <v-card
    class="overflow-hidden mb-5"
  >
    <v-card-title class="d-flex justify-space-between">
      <v-btn
        icon
        @click="$emit('toggleShow', 'visio')"
      >
        <v-icon>{{ show ? icons.mdiChevronUp : icons.mdiChevronDown }}</v-icon>
      </v-btn>
      <h4>
        Visio-conférence
      </h4>
      <v-icon
        top
        right
      >
        {{ icons.mdiCalendar }}
      </v-icon>
    </v-card-title>
    <v-expand-transition>
      <div v-show="show">
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col
              cols="2"
              class="d-flex justify-center"
            >
              Tuteur :
            </v-col>

            <v-col
              cols="8"
            >
              <v-select
                v-model="tutor"
                :items="tutors"
                item-text="displayName"
                item-value="id"
                :disabled="!selectingTutor"
                placeholder="Pas de tuteur selectionné"
                dense
                @input="updateTutor"
              >
              </v-select>
            </v-col>
            <v-col
              cols="2"
            >
              <v-btn
                icon
                text
                @click="selectingTutor =true"
              >
                <v-icon>
                  {{ icons.mdiPencil }}
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text class="d-flex flex-column">
          <v-textarea
            v-model="tutorNote"
            rows="3"
            label="Notes tutorat"
          >
          </v-textarea>
          <v-btn
            :disabled="noteChanged"
            text
            color="primary"
            @click="updateNote"
          >
            Sauvegarder
          </v-btn>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text>
          <StudentMeetingTimeTable
            :given-height="meetings.length > 4 ? 350 : (meetings.length +1) *100"
            :student="student"
            :meetings="meetings"
            @meetingDeleted="meetingDeleted"
            @selectMeetingEdit="selectMeetingEdit"
          ></StudentMeetingTimeTable>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            color="primary"
            class="mb-5"
            @click.stop="meetingDialog = true "
          >
            Nouveau Visio
          </v-btn>
        </v-card-actions>
        <NewMeetingDialog
          :meeting-dialog="meetingDialog"
          :meetings="meetings"
          :student="student"
          @closeDialog="meetingDialog = false"
          @meetingAdded="meetingAdded"
        >
        </NewMeetingDialog>
        <EditMeetingDialog
          :edit-meeting-dialog="editMeetingDialog"
          :meeting-id="selectedEventEditId"
          :student-id="selectedEventStudentId"
          @closeEditDialog="editMeetingDialog = false"
          @meetingUpdated="updateMeeting"
          @meetingDeleted="meetingDeleted"
        ></EditMeetingDialog>
      </div>
    </v-expand-transition>
  </v-card>
</template>

<script>
import {
  mdiCalendar, mdiChevronUp, mdiChevronDown, mdiPencil,
} from '@mdi/js'
import NewMeetingDialog from '@/components/NewMeetingDialog.vue'
import StudentMeetingTimeTable from '@/components/StudentMeetingTimeTable.vue'
import EditMeetingDialog from '@/components/EditMeetingDialog.vue'

export default {
  components: {
    NewMeetingDialog,
    StudentMeetingTimeTable,
    EditMeetingDialog,
  },
  props: {
    student: {
      type: Object,
      default: () => {},
    },
    meetings: {
      type: Array,
      default: () => [],
    },
    show: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      editMeetingDialog: false,
      selectedEventEditId: null,
      selectedEventStudentId: null,
      meetingDialog: false,
      tutor: null,
      tutors: this.$store.getters.getTutors,
      selectingTutor: false,
      tutorNote: '',
      icons: {
        mdiCalendar,
        mdiChevronUp,
        mdiChevronDown,
        mdiPencil,
      },
    }
  },
  computed: {
    noteChanged() {
      return (this.student.tutorNote === this.tutorNote)
    },
  },
  mounted() {
    this.fetchTuteur()
    this.tutorNote = this.student.tutorNote
  },
  methods: {
    fetchTuteur() {
      this.$http.get(`${process.env.VUE_APP_API_URL}/users-permissions/find-tutor/${this.student.id}`)
        .then(res => {
          if (res.status === 200) {
            this.tutor = res.data.id
            this.selectingTutor = false
          }
        })
        .catch(err => {
          this.selectingTutor = true
          if (err.response.status === 404) {
            this.tutor = null
          } else {
            this.$store.commit('snackMe', {
              color: 'error',
              text: 'Erreur lors de la récupération du tuteur',
              value: true,
            })
            console.log(err)
          }
        })
    },
    selectMeetingEdit(payload) {
      this.selectedEventEditId = payload.id
      this.selectedEventStudentId = payload.students[0].id
      this.editMeetingDialog = true
    },
    meetingAdded(payload) {
      this.$emit('meetingAdded', payload)
    },
    updateMeeting(payload) {
      this.$emit('meetingUpdated', payload)
    },
    meetingDeleted(payload) {
      if (payload === this.electedEventEditId) {
        this.selectedEventEditId = null
      }
      this.$emit('meetingDeleted', payload)
    },
    updateNote() {
      this.$http.put(`${process.env.VUE_APP_API_URL}/students/${this.student.id}`, { tutorNote: this.tutorNote })
        .then(res => {
          console.log(res)
        })
        .catch(err => console.log(err))
        .finally()
    },
    updateTutor(e) {
      this.$http.post(`${process.env.VUE_APP_API_URL}/users-permissions/update-tutors/${this.student.id}`, {
        tutorId: e,
      })
        .then(res => {
          if (res.status === 200) {
            this.$store.commit('snackMe', {
              color: 'success',
              text: 'Tuteur mis à jour ',
              value: true,
            })
            this.selectingTutor = false
          }
        })
        .catch(err => {
          console.log(err.response)
          this.$store.commit('snackMe', {
            color: 'error',
            text: 'Erreur lors de la mise à jour du tuteur',
            value: true,
          })
        })
        .finally()
    },
  },
}
</script>

<style>

</style>
