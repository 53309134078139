<template>
  <div>
    <v-dialog
      v-model="dialog"
      width="700"
      persistent
      @input="$emit('input', $event)"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Informations du contrat
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col>
              <v-text-field
                v-model="student.nom"
                label="Nom"
                readonly
                dense
              >
              </v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="student.prenom"
                label="Prénom"
                readonly
                dense
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="student.price"
                label="Prix"
                readonly
                dense
              >
              </v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="student.formation.nom"
                label="Formation"
                readonly
                dense
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="conventionData.certification"
                label="Certification"
                readonly
                dense
              >
              </v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="student.tuteurs[0].nom"
                label="Tuteur"
                readonly
                dense
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                v-model="conventionData.debutFormation"
                label="Date de début"
                readonly
                dense
              >
              </v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="conventionData.finFormation"
                label="Date de fin"
                readonly
                dense
              >
              </v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="conventionData.retractation"
                label="Rétractation"
                readonly
                dense
              >
              </v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="nbheures"
                label="Durée (h)"
                readonly
                dense
                :hint="`${student.formation.duree}h dont ${student.formation.visio}h de visio`"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="generateConvention"
          >
            Générer le contrat
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            text
            @click="$emit('closeModal')"
          >
            Annuler
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

export default {
  props: {
    student: {
      type: Object,
      default: () => { },
    },
    dialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      conventionData: {
        debutFormation: new Date(),
        finFormation: new Date(),
        retractation: new Date(),
        dateNow: this.$date(new Date(), 'dd/MM/yyyy'),
        certification: '',
      },
    }
  },
  computed: {
    echeance() {
      return this.$date(new Date(new Date().setDate(new Date().getDate() + 30)), 'dd/MM/yyyy')
    },
    nbheures() {
      return this.student.formation.duree
    },
  },
  watch: {
    dialog(val) {
      if (val) {
        this.conventionData.debutFormation = this.$date(new Date(this.student.debutFormation), 'dd/MM/yyyy')
        this.conventionData.finFormation = this.$date(new Date(this.student.finFormation), 'dd/MM/yyyy')
        this.conventionData.retractation = this.$date(new Date(this.$workingDays.getWorkingDaysBefore(this.student.debutFormation, 7)), 'dd/MM/yyy')
        this.$http.get(`${process.env.VUE_APP_API_URL}/certifications/${this.student.formation.certification}`)
          .then(res => {
            this.conventionData.certification = res.data.nom
          })
          .catch(err => console.log(err))
      }
    },
  },
  methods: {
    niceDate(date) {
      return this.$date(new Date(date), 'dd/MM/yyyy')
    },
    generateConvention() {
      // FORMAT DATA FOR PDF
      this.conventionData.student = this.student
      this.conventionData.nbheures = this.nbheures

      // POST TO PDF GENERATOR
      this.$http.post(`${process.env.VUE_APP_API_URL}/conventions/generate-pdf/`, this.conventionData)
        .then(res => {
          console.log(res.data)
          this.$emit('conventionGenerated', res.data)
          this.$store.commit('snackMe', {
            color: 'success',
            text: 'Convention générée avec succès',
            value: true,
          })
        })

        .catch(error => console.error(error))
    },
  },
}
</script>

<style>

</style>
