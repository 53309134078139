<template>
  <v-card class="overflow-hidden mt-5">
    <v-card-title class="d-flex justify-space-between">
      <v-btn
        icon
        @click="$emit('toggleShow', 'formation')"
      >
        <v-icon>{{ show ? icons.mdiChevronUp : icons.mdiChevronDown }}</v-icon>
      </v-btn>
      <h4>
        Formation
      </h4>
      <v-icon
        top
        right
      >
        {{ icons.mdiCertificateOutline }}
      </v-icon>
    </v-card-title>
    <v-divider>
    </v-divider>
    <v-expand-transition>
      <div v-show="show">
        <v-row
          class="ma-4 h-full"
          justify="center"
        >
          <v-col
            cols="12"
            class="text-center"
          >
            <h2 class="mb-5">
              {{ student.formation.nom }} ( {{ student.formation.duree }} heures)
            </h2>
            <p>
              En formation du  <strong>{{ new Date(student.debutFormation) | date('dd MMMM yyyy') }} </strong> au  <strong>{{ new Date(student.finFormation) | date('dd MMMM yyyy') }}</strong>
            </p>
            <v-spacer>
            </v-spacer>
            <p>Jour de rétractation : <b>{{ this.$workingDays.getWorkingDaysBefore(student.debutFormation, 7) | date('dd MMMM yyyy') }}</b></p>
            <v-spacer>
            </v-spacer>
            <p>
              Durée de formation :
              <b>
                {{ durees.find(x => x.value === student.dureeFormation).name }}
              </b>
            </p>
            <p v-if="student.formation.visio">
              Durée visio : <b>{{ student.formation.visio }} heure(s)</b>
            </p>
            <div v-else>
              <p>
                Visio : <b>
                  {{ student.visio ? 'Oui' : 'Non' }}
                </b>
              </p>
              <v-spacer>
              </v-spacer>
              <p v-if="student.visio">
                Durée visio : <b>{{ student.dureeVisio }} heure(s)</b>
              </p>
            </div>
            <v-divider class="mt-5">
            </v-divider>
          </v-col>
        </v-row>
        <v-row
          class="ma-4 h-full"
          justify="center"
        >
          <v-col
            md="6"
            sm="12"
            class="text-center"
          >
            <a
              :href="`https://www.of.moncompteformation.gouv.fr/espace-prive/html/#/dossiers/detail/${student.num_dossier}`"
              target="_blank"
            >
              <v-icon>
                {{ icons.mdiApplicationImport }}
              </v-icon>
            </a>
            {{ student.num_dossier }}
          </v-col>
          <v-col
            md="6"
            sm="12"
            class="text-center"
          >
            <v-icon>
              {{ icons.mdiCurrencyEur }}
            </v-icon>
            {{ student.price }}
          </v-col>
          <v-col
            v-if="student.tuteurs"
            md="6"
            sm="12"
            class="text-center"
          >
            <v-icon>
              {{ icons.mdiAccountBoxOutline }}
            </v-icon>
            Tutorat par : <br /><b>{{ student.tuteurs.length > 0 ? student.tuteurs[0].nom : '' }}</b>
          </v-col>
          <v-col
            v-if="certifications"
            md="6"
            sm="12"
            class="text-center"
          >
            <v-icon>
              {{ icons.mdiClipboardAccountOutline }}
            </v-icon>
            Certification : <br />{{ certifications[0].nom }}
          </v-col>
        </v-row>
      </div>
    </v-expand-transition>
  </v-card>
</template>

<script>

import {
  mdiApplicationImport, mdiCurrencyEur, mdiAccountBoxOutline, mdiClipboardAccountOutline,
  mdiCertificateOutline, mdiChevronUp, mdiChevronDown,
} from '@mdi/js'

export default {
  props: {
    student: {
      type: Object,
      default: () => {},
    },
    show: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      certifications: [
        {
          nom: '',
        },
      ],
      durees: [
        {
          name: '2 mois',
          value: 2,
        },
        {
          name: '3 semaines',
          value: 3,
        },
        {
          name: '1 mois',
          value: 4,
        },
        {
          name: '5 semaines',
          value: 5,
        },
        {
          name: '6 semaines',
          value: 6,
        },
        {
          name: '2 mois',
          value: 8,
        },
        {
          name: '9 semaines',
          value: 9,
        },
        {
          name: '10 semaines',
          value: 10,
        },
        {
          name: '3 mois',
          value: 12,
        },
      ],
      icons: {
        mdiApplicationImport,
        mdiCurrencyEur,
        mdiAccountBoxOutline,
        mdiClipboardAccountOutline,
        mdiCertificateOutline,
        mdiChevronUp,
        mdiChevronDown,
      },
    }
  },
  mounted() {
    this.$http.get(`${process.env.VUE_APP_API_URL}/formations/${this.student.formation.id}`)
      .then(form => {
        this.certifications = form.data.certifications
      })
      .catch(error => console.error(error))
  },

}
</script>

<style>

</style>
