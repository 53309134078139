<template>
  <v-card-text>
    <v-simple-table
      v-if="meetings.length > 0"
      :height="givenHeight"
    >
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-center">
              Total heures de visio
            </th>
            <th class="text-center">
            </th>
            <th class="text-center">
              {{ durees.visio / 60 }} h
            </th>
            <th
              v-if="enableActions"
              class="text-center"
            >
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(meeting, index) in orderedMeetings"
            :key="index"
            @click="editMeeting(meeting)"
          >
            <td
              class="text-center"
              :class="isPast(meeting.start) ? 'strike': ''"
            >
              <b>{{ meeting.nom }}</b> <br>
              <i> avec {{ meeting.users[0].displayName }}</i>
            </td>
            <td class="text-center">
              {{ new Date(meeting.start) | date('dd MMMM yyyy hh:mm') }}
            </td>
            <td class="text-center">
              - {{ meeting.duration / 60 }} h
            </td>
            <td
              v-if="enableActions"
              class="text-center"
            >
              <v-btn
                right
                color="error"
                fab
                small
                @click.prevent="confirmDeleteMeeting(meeting.id)"
              >
                <v-icon>{{ icons.mdiTrashCanOutline }}</v-icon>
              </v-btn>
            </td>
          </tr>
          <tr>
            <td class="text-center">
              <strong>Restant</strong>
            </td>
            <td></td>
            <td class="text-center">
              <strong>{{ (durees.visio - durees.meetings) /60 }} h</strong>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <p
      v-else
      class="text-center"
    >
      Aucun visio pour l'instant
    </p>
    <v-dialog
      v-model="confirmDeleteMeetingDialog"
      width="900"
      @click:outside="confirmDeleteMeetingDialog = false"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Confirmation
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          Êtes-vous sûr(e) de vouloir supprimer l'évènement ?
        </v-card-text>
        <v-card-actions
          class="d-flex justify-center"
        >
          <v-btn
            outlined
            color="secondary"
            @click="confirmDeleteMeetingDialog = false"
          >
            Annuler
          </v-btn>
          <v-btn
            color="error"
            @click.prevent="deleteMeeting()"
          >
            Supprimer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card-text>
</template>

<script>

import {
  mdiTrashCanOutline,

} from '@mdi/js'

export default {
  props: {
    givenHeight: {
      type: Number,
      default: 250,
    },
    enableActions: {
      type: Boolean,
      defalt: false,
    },
    meetings: {
      type: Array,
      default: () => [],
    },
    student: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      meetingToDelete: null,
      confirmDeleteMeetingDialog: false,
      fetchedFormation: {},
      icons: {
        mdiTrashCanOutline,
      },
    }
  },
  computed: {
    durees() {
      let formationToUse = {}
      if (typeof this.student.formation === 'number') {
        formationToUse = this.fetchedFormation
      } else {
        formationToUse = this.student.formation
      }
      const durees = {}
      durees.formation = Number(formationToUse.durees) * 60
      durees.visio = Number(formationToUse.visio) * 60
      durees.meetings = 0
      this.meetings.forEach(meeting => {
        durees.meetings += Number(meeting.duration)
      })

      return durees
    },
    orderedMeetings() {
      const meetings = [...this.meetings]

      return meetings.sort((a, b) => new Date(a.end) - new Date(b.end))
    },
  },
  mounted() {
    if (typeof this.student.formation === 'number') {
      this.fetchFormation()
    }
  },
  methods: {
    confirmDeleteMeeting(id) {
      this.meetingToDelete = id
      this.confirmDeleteMeetingDialog = true
    },
    isPast(date) {
      const dateNow = new Date()

      return new Date(date) < dateNow
    },
    editMeeting(e) {
      if (this.enableActions) {
        return
      }
      this.$emit('selectMeetingEdit', e)
    },
    deleteMeeting() {
      this.$http.delete(`${process.env.VUE_APP_API_URL}/meetings/${this.meetingToDelete}`)
        .then(res => {
          if (res.status === 200) {
            this.$store.commit('snackMe', {
              color: 'success',
              text: 'RDV supprimé avec succès',
              value: true,
            })
            this.confirmDeleteMeetingDialog = false
            const deletedMeetingId = res.data.id
            this.$emit('meetingDeleted', deletedMeetingId)
          }
        })
        .catch(err => {
          console.log(err)
          this.$store.commit('snackMe', {
            color: 'error',
            text: 'Erreur lors de la suppression du RDV.',
            value: true,
          })
        })
    },
    fetchFormation() {
      this.$http.get(`${process.env.VUE_APP_API_URL}/formations/${this.student.formation}`)
        .then(res => {
          this.fetchedFormation = res.data
        })
        .catch(err => console.log(err))
        .finally()
    },
  },

}
</script>

<style>
 .strike {
  text-decoration: line-through;
 }
</style>
