<template>
  <v-dialog
    v-if="!loading"
    v-model="editMeetingDialog"
    persistent
    width="900"
  >
    <v-card :loading="loading">
      <v-card-title class="text-h5 grey lighten-2">
        Modifier Visio-conférence
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-row>
          <v-col>
            <v-text-field
              v-model="studentName"
              disabled
              dense
              outlined
              label="Choisir l'apprenant"
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <StudentMeetingTimeTable
        v-if="!loading"
        :enable-actions="true"
        :student="meeting.students[0]"
        :meetings="selectedMeetings"
        @meetingDeleted="meetingDeleted"
      >
      </StudentMeetingTimeTable>
      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <v-row>
            <v-col>
              <v-text-field
                v-model="meeting.nom"
                :rules="[v => !!v || 'Le nom est requis']"
                dense
                outlined
                label="Nom de la réunion"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              md="6"
            >
              <v-menu
                ref="menuDateStart"
                v-model="menuDateStart"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="dateStartFormatted"
                    dense
                    outlined
                    label="Date de la réunion"
                    :prepend-icon="icons.mdiCalendar"
                    v-bind="attrs"
                    @blur="dateStart = parseDate(dateStartFormatted)"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="dateStart"
                  locale="fr"
                  no-title
                  @input="menuDateStart = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col
              md="3"
            >
              <v-autocomplete
                v-model="startTime"
                label="de"
                :items="intervals"
                auto-select-first
                dense
                outlined
                @change="changeEndtime"
              ></v-autocomplete>
            </v-col>
            <v-col md="3">
              <v-autocomplete
                v-model="endTime"
                label="à"
                outlined
                :items="intervalsFiltered"
                auto-select-first
                dense
              ></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="text-center">
              <p>Prochain rendez-vous prévu le <strong>{{ dateStartFull | date('dd MMMM yyyy') }}</strong> de <strong>{{ dateStartFull | date('HH:mm') }}</strong> à <strong>{{ dateEndFull | date('HH:mm') }}</strong> ({{ eventDuration/60 }} h)</p>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-select
                v-model="meeting.users[0]"
                outlined
                dense
                :rules="[v => !!v || 'Le tuteur est requis']"
                label="Tuteur"
                :items="tuteurs"
                item-text="displayName"
                item-value="id"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-textarea
                v-model="meeting.note"
                outlined
                dense
                label="Note"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-btn
          color="primary"
          outlined
          @click="$emit('closeEditDialog')"
        >
          Annuler
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          text
          @click="editMeeting"
        >
          Modifier
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiCalendar } from '@mdi/js'
import StudentMeetingTimeTable from './StudentMeetingTimeTable.vue'

export default {
  components: {
    StudentMeetingTimeTable,
  },
  props: {
    editMeetingDialog: {
      type: Boolean,
      default: false,
    },
    meetingId: {
      type: Number,
      default: null,
    },
    studentId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      loading: true,
      valid: true,
      student: {},
      meeting: {
        students: [
          {
            prenom: '',
            nom: '',
          },
        ],
      },
      studentName: '',
      selectedStudent: null,
      selectedMeetings: null,
      menuDateStart: false,
      startTime: '10:15',
      endTime: '11:15',
      dateStart: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      dateStartFormatted: this.$date(new Date(), 'dd/MM/yyyy'),
      icons: {
        mdiCalendar,
      },
    }
  },
  computed: {
    tuteurs() {
      let tuteurs = [...this.$store.state.userlist]
      tuteurs = tuteurs.filter(x => x.status === 'tuteur')

      return tuteurs
    },
    intervals() {
      const intervals = []
      const date = new Date()
      const format = {
        hour: 'numeric',
        minute: 'numeric',
      }

      for (let minutes = 0; minutes < 24 * 60; minutes += 15) {
        date.setHours(0)
        date.setMinutes(minutes)
        intervals.push(date.toLocaleTimeString('fr', format))
      }

      return intervals
    },
    dateStartFull() {
      if (!this.startTime) return null
      const [hours, minutes] = this.startTime.split(':')
      const date = new Date(this.dateStart)
      date.setHours(hours, minutes, 0)

      return date
    },
    dateEndFull() {
      if (!this.endTime) return null
      const [hours, minutes] = this.endTime.split(':')
      const date = new Date(this.dateStart)
      date.setHours(hours, minutes, 0)

      return date
    },
    eventDuration() {
      const diff = (this.dateEndFull.getTime() - this.dateStartFull.getTime())
      const diffmins = Math.abs((diff / 1000) / 60)

      return diffmins
    },
    intervalsFiltered() {
      let index = this.intervals.findIndex(x => x === this.startTime)
      index += 1

      return this.intervals.slice(index)
    },
  },
  watch: {
    meetingId(val) {
      if (val) {
        this.fetchData()
      }
    },
    dateStart(val) {
      this.dateStartFormatted = this.formatDate(val)
    },
  },
  methods: {
    fetchData() {
      this.loading = true
      Promise.all([this.fetchMeeting(), this.fetchMeetings()])
        .then(values => {
          [this.meeting, this.selectedMeetings] = values
          this.dateStartFormatted = this.formatDate(new Date(this.meeting.start))
          this.dateStart = this.parseDate(this.dateStartFormatted)
          this.startTime = this.$date(new Date(this.meeting.start), 'HH:mm')
          this.endTime = this.$date(new Date(this.meeting.end), 'HH:mm')
          this.studentName = `${this.meeting.students[0].prenom} ${this.meeting.students[0].nom}`
          this.loading = false
        })
        .catch(err => console.log(err))
        .finally()
    },
    async fetchMeeting() {
      const res = await this.$http.get(`${process.env.VUE_APP_API_URL}/meetings/${this.meetingId}`)

      return res.data
    },
    async fetchMeetings() {
      const res = await this.$http.get(`${process.env.VUE_APP_API_URL}/meetings/?students=${this.studentId}`)

      return res.data
    },
    async fetchStudent() {
      const res = await this.$http.get(`${process.env.VUE_APP_API_URL}/students/${this.studentId}`)

      return res.data
    },
    changeEndtime(e) {
      const index = this.intervals.findIndex(x => x === e)

      this.endTime = this.intervals[index + 4]
    },
    formatDate(date) {
      if (!date) return null

      return this.$date(new Date(date), 'dd/MM/yyyy')
    },
    parseDate(date) {
      if (!date) return null

      const [day, month, year] = date.split('/')

      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    meetingDeleted(e) {
      this.$emit('meetingDeleted', e)
    },
    editMeeting() {
      const meeting = {
        nom: this.meeting.name,
        start: this.dateStartFull,
        end: this.dateEndFull,
        note: this.meeting.note,
        users: this.meeting.users[0].id,
        students: this.meeting.students[0].id,
        duration: this.eventDuration,
      }
      this.$http.put(`${process.env.VUE_APP_API_URL}/meetings/${this.meetingId}`, meeting)
        .then(res => {
          this.$emit('meetingUpdated', res.data)
          this.$emit('closeEditDialog')
          this.$store.commit('snackMe', {
            color: 'success',
            text: 'RDV mis à jour avec succès',
            value: true,
          })
        })
        .catch(err => {
          this.$store.commit('snackMe', {
            color: 'error',
            text: 'Erreur lors de la mise à jour du RDV.',
            value: true,
          })
          console.log(err)
        })
        .finally()
    },
  },
}

</script>

<style>

</style>
