<template>
  <v-card
    class="overflow-hidden mb-5 justify-center"
  >
    <v-card-title class="d-flex justify-space-between">
      <v-btn
        icon
        @click="$emit('toggleShow', 'tasks')"
      >
        <v-icon>{{ show ? icons.mdiChevronUp : icons.mdiChevronDown }}</v-icon>
      </v-btn>
      <h4>
        Tâches
      </h4>
      <v-icon
        top
        right
      >
        {{ icons.mdiFormatListChecks }}
      </v-icon>
    </v-card-title>
    <v-expand-transition>
      <div v-show="show">
        <v-divider></v-divider>
        <v-list>
          <StudenSingleTaskCardListItemVue
            v-for="(task, index) in tasks"
            :key="index"
            :task="task"
          ></StudenSingleTaskCardListItemVue>
        </v-list>
      </div>
    </v-expand-transition>
  </v-card>
</template>

<script>

import {
  mdiFormatListChecks,
  mdiChevronUp,
  mdiChevronDown,
} from '@mdi/js'
import StudenSingleTaskCardListItemVue from '@/components/StudentSingle/StudentSingleTaskCardListItem.vue'

export default {
  components: {
    StudenSingleTaskCardListItemVue,
  },
  props: {
    tasks: {
      type: Array,
      default: () => [],
    },
    show: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      icons: {
        mdiFormatListChecks,
        mdiChevronDown,
        mdiChevronUp,
      },
    }
  },

}
</script>

<style>

</style>
