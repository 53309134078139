<template>
  <v-list-item>
    <v-list-item-content>
      <v-list-item-title>
        <h4>
          {{ niceType(task.type) }}
        </h4>
      </v-list-item-title>
      <v-list-item-subtitle>
        {{ new Date(task.date) | date('dd MMM à HH:mm') }}
      </v-list-item-subtitle>
      <v-list-item-subtitle>
        Attribué à : {{ task.users_permissions_user ? task.users_permissions_user.displayName : 'Personne' }}
      </v-list-item-subtitle>
      <v-list-item-subtitle v-if="task.note">
        <b>Note :</b>
        {{ task.note }}
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-icon
      class="me-4"
      @click="completeTask(task.id)"
    >
      <v-icon
        size="35"
        class="hover-i-green"
      >
        {{ icons.mdiCheckCircleOutline }}
      </v-icon>
    </v-list-item-icon>
    <v-list-item-icon
      @click="deleteTask(task.id)"
    >
      <v-icon
        size="35"
        class="hover-i-red"
      >
        {{ icons.mdiDeleteCircleOutline }}
      </v-icon>
    </v-list-item-icon>
    <v-dialog
      v-model="forwardDialog"
      width="500"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-list-item-icon
          v-bind="attrs"
          v-on="on"
        >
          <v-icon
            size="35"
            class="hover-i"
          >
            {{ icons.mdiArrowUpThinCircleOutline }}
          </v-icon>
        </v-list-item-icon>
      </template>
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Transférer la tâche à :
        </v-card-title>
        <v-card-text>
          <v-select
            v-model="forwardTo"
            :items="$store.state.userlist"
            item-text="displayName"
            item-value="id"
            label="Attribuer à"
            outline
          ></v-select>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="forwardTask(task.id)"
          >
            Valider
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-list-item>
</template>

<script>

import { mdiCheckCircleOutline, mdiDeleteCircleOutline, mdiArrowUpThinCircleOutline } from '@mdi/js'

export default {
  props: {
    task: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      forwardDialog: false,
      forwardTo: '',
      icons: {
        mdiCheckCircleOutline,
        mdiDeleteCircleOutline,
        mdiArrowUpThinCircleOutline,
      },
    }
  },
  methods: {
    niceType(a) {
      switch (a) {
        case 'entree':
          return 'Entrée en formation'
        case 'sortie':
          return 'Sortie de formation'
        default:
          return 'Rappel'
      }
    },
    goToStudent(e) {
      this.$router.push(`/student/view/${e}`)
      this.$router.go()
    },
    completeTask(t) {
      this.$store.dispatch('completeTask', { id: t })
    },
    deleteTask(t) {
      this.$store.dispatch('deleteTask', { id: t })
    },
    forwardTask(t) {
      this.$store.dispatch('forwardTask', { id: t, users_permissions_user: this.forwardTo })
      this.forwardDialog = false
    },
  },
}
</script>

<style>

</style>
