<template>
  <div>
    <v-row
      class="ma-0 h-full"
    >
      <!-- Carte Info Persos -->
      <v-col
        md="6"
        sm="12"
      >
        <div v-if="loading">
          <v-skeleton-loader
            type="article,list-item-three-line, list-item-three-line,list-item-three-line, actions"
            class="mb-4"
          ></v-skeleton-loader>
          <v-skeleton-loader
            type="article,list-item-three-line, list-item-three-line,list-item-two-line"
            class="mb-4"
          ></v-skeleton-loader>
          <v-skeleton-loader
            type="table"
          ></v-skeleton-loader>
        </div>
        <template v-else>
          <StudentSinglePersonnalInfosCardVue
            :student="student"
            :show="cardPreference.info"
            @toggleShow="toggleShow"
          ></StudentSinglePersonnalInfosCardVue>

          <!-- Carte Formation -->
          <StudentSingleFormationCardVue
            :show="cardPreference.formation"
            :student="student"
            @toggleShow="toggleShow"
          ></StudentSingleFormationCardVue>
          <!-- Fin Carte Formations -->
          <!-- DOCUMENTS -->
          <StudentSingleDocumentsCardVue
            :show="cardPreference.documents"
            :student="student"
            @toggleShow="toggleShow"
          ></StudentSingleDocumentsCardVue>
        <!-- FIN DOCUMENTS-->
        </template>
      </v-col>

      <!-- Colonne de droite-->
      <v-col
        md="6"
        sm="12"
      >
        <div v-if="loading">
          <v-skeleton-loader
            type="article, list-item-three-line, list-item-three-line, actions"
            class="mb-4"
          ></v-skeleton-loader>
          <v-skeleton-loader
            type="article, actions"
            class="mb-4"
          ></v-skeleton-loader>
          <v-skeleton-loader
            type="article, actions"
            class="mb-4"
          ></v-skeleton-loader>
          <v-skeleton-loader
            type="article, actions"
            class="mb-4"
          ></v-skeleton-loader>
        </div>
        <template v-else>
          <!-- CARTE TASKS -->
          <StudentSingleTasksCardVue
            v-if="$store.getters.getTasksByStudent(student.id).length > 0"
            :show="cardPreference.tasks"
            :tasks="$store.getters.getTasksByStudent(student.id)"
            @toggleShow="toggleShow"
          ></StudentSingleTasksCardVue>
          <!-- END CARTE TASK -->
          <!-- MEETINGS CARD -->
          <StudentSingleMeetingsCardVue
            :show="cardPreference.visio"
            :student="student"
            :meetings="meetings"
            @meetingAdded="meetingAdded"
            @toggleShow="toggleShow"
            @meetingUpdated="updateMeeting"
            @meetingDeleted="meetingDeleted"
          ></StudentSingleMeetingsCardVue>
          <!-- END MEETING CARD -->

          <!-- CARTE RAPPEL -->
          <StudentSingleRappelCardVue
            :show="cardPreference.rappel"
            :student="student"
            @updateStudent="studentUpdate"
            @toggleShow="toggleShow"
          ></StudentSingleRappelCardVue>
          <!-- FIN CARTE RAPPEL -->
          <!-- NOUVEAU CONTACT-->
          <StudentSingleNewContactCardVue
            :show="cardPreference.contact"
            :student="student"
            :contacts="student.contacts"
            @toggleShow="toggleShow"
            @newContact="newContact"
          ></StudentSingleNewContactCardVue>
        <!-- FIN NOUVEAU CONTACT-->
        </template>
      </v-col>
    </v-row>
    <v-row
      class="ma-0 h-full"
    >
      <!-- NOUVELLE CARTE CONTACTS -->
      <v-col
        cols="12"
      >
        <v-skeleton-loader
          v-if="loading"
          type="article, actions"
        ></v-skeleton-loader>
        <StudentSingleContactHistoryCardsVue
          v-else
          :show="cardPreference.history"
          :order-contact="orderContact"
          :student-contacts="student.contacts"
          :student-id="student.id"
          @studentUpdate="studentUpdate"
          @toggleShow="toggleShow"
        ></StudentSingleContactHistoryCardsVue>
      </v-col>
      <!-- CARTE FIN CONTACTS -->
    </v-row>
    <v-row
      class="ma-0 h-full"
    >
      <v-col>
        <v-skeleton-loader
          v-if="loading"
          type="article, actions"
        ></v-skeleton-loader>
        <!-- CARTE FICHIERS -->
        <StudentSingleFichiersCardVue
          v-else
          :show="cardPreference.files"
          :fichiers="student.fichier"
          :student-id="student.id"
          @studentUpdate="studentUpdate"
          @toggleShow="toggleShow"
        ></StudentSingleFichiersCardVue>
      <!-- FIN CARTE FICHIERS -->
      </v-col>
    </v-row>
  </div>
</template>

<script>

import StudentSingleContactHistoryCardsVue from '@/components/StudentSingle/StudentSingleContactHistoryCards.vue'
import StudentSingleDocumentsCardVue from '@/components/StudentSingle/StudentSingleDocumentsCard.vue'
import StudentSinglePersonnalInfosCardVue from '@/components/StudentSingle/StudentSinglePersonnalInfosCard.vue'
import StudentSingleTasksCardVue from '@/components/StudentSingle/StudentSingleTasksCard.vue'
import StudentSingleFormationCardVue from '@/components/StudentSingle/StudentSingleFormationCard.vue'
import StudentSingleRappelCardVue from '@/components/StudentSingle/StudentSingleRappelCard.vue'
import StudentSingleNewContactCardVue from '@/components/StudentSingle/StudentSingleNewContactCard.vue'
import StudentSingleFichiersCardVue from '@/components/StudentSingle/StudentSingleFichiersCard.vue'
import StudentSingleMeetingsCardVue from '@/components/StudentSingle/StudentSingleMeetingsCard.vue'

export default {

  components: {
    StudentSingleContactHistoryCardsVue,
    StudentSingleDocumentsCardVue,
    StudentSinglePersonnalInfosCardVue,
    StudentSingleTasksCardVue,
    StudentSingleFormationCardVue,
    StudentSingleRappelCardVue,
    StudentSingleNewContactCardVue,
    StudentSingleFichiersCardVue,
    StudentSingleMeetingsCardVue,
  },
  props: {

  },
  data() {
    return {
      loading: true,
      student: {},
      meetings: [],
      cardPreference: this.$store.state.user.preferences,
      studentStatus: {
        enFormation: {
          color: 'primary',
          text: 'En formation',
        },
        avantFormation: {
          color: 'success',
          text: 'Pas encore entré en formation',
        },
        sortieFormation: {
          color: 'warning',
          text: 'Sorti de formation',
        },
        autre: {
          color: 'info',
          text: 'Autre',
        },
      },

      statusExpand: {
        rappel:
      {
        color: 'primary',
        text: 'A rappeler',
      },
        suivi:
      {
        color: 'info',
        text: 'Suivi de dossier',
      },
        enFormation:
      {
        color: 'primary',
        text: 'Dossier en cours',
      },
        debutForm:
      {
        color: 'primary',
        text: 'Début de Formation',
      },
        finForm:
      {
        color: 'primary',
        text: 'Fin de Formation',
      },
        dead:
      {
        color: 'error',
        text: 'Caca',
      },
        nrp:
      {
        color: 'warning',
        text: 'Ne répond pas',
      },
        autre:
      {
        color: 'secondary',
        text: 'Autre',
      },
      },
    }
  },
  computed: {
    orderContact() {
      if (this.student.contacts) {
        const arrangedContacts = []
        arrangedContacts.push(
          {
            contacter: 'CRM',
            contactedby: 'formation',
            note: 'Début de la formation',
            contactDate: new Date(this.student.debutFormation),
            contactResult: 'debutForm',
            id: null,
          },
          {
            contacter: 'CRM',
            contactedby: 'formation',
            note: 'Fin de la formation',
            contactDate: new Date(this.student.finFormation),
            contactResult: 'finForm',
            id: null,
          },
        )

        return [...arrangedContacts, ...this.student.contacts].sort((a, b) => new Date(b.contactDate) - new Date(a.contactDate))
      }

      return []
    },
  },
  watch: {
    // call again the method if the route changes
    $route: 'fetchData',

  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      Promise.all([this.fetchStudent(), this.fetchMeetings()])
        .then(values => {
          [this.student, this.meetings] = values
        })
        .catch(error => console.log(error))
        .finally(() => {
          if (this.student) {
            this.loading = false
          }
        })
    },
    async fetchStudent() {
      const res = await this.$http.get(`${process.env.VUE_APP_API_URL}/students/${this.$route.params.id}`)

      return res.data
    },
    async fetchMeetings() {
      const res = await this.$http.get(`${process.env.VUE_APP_API_URL}/meetings/?students=${this.$route.params.id}`)

      return res.data
    },
    studentUpdate(e) {
      Object.keys(e).forEach(key => {
        this.student[key] = e[key]
      })
    },
    newContact(payload) {
      this.student.contacts = payload.contacts
    },
    updateStatus(e) {
      this.$http.put(`${process.env.VUE_APP_API_URL}/students/${this.student.id}`, { status: e })
        .then(response => {
          this.student.status = response.data.status
          this.$store.commit('snackMe', {
            color: 'success',
            text: 'Status changé avec succès.',
            value: true,
          })
        })
        .catch(error => {
          this.$store.commit('snackMe', {
            color: 'error',
            text: 'Erreur lors de la mise à jour du status',
            value: true,
          })
          console.log(error)
        })
    },
    getIcon(a) {
      const iconToGet = this.contactWays.find(x => x.value === a).icon

      return this.icons[iconToGet]
    },
    meetingAdded(payload) {
      this.meetings.push(payload)
    },
    meetingDeleted(payload) {
      const meetings = this.meetings.filter(obj => obj.id !== payload)
      this.meetings = []
      this.meetings = meetings
    },
    toggleShow(payload) {
      this.cardPreference[payload] = !this.cardPreference[payload]
      this.$nextTick(() => {
        this.$store.dispatch('updateUserPreferences', this.cardPreference)
      })
    },
    updateMeeting(payload) {
      const meetings = [...this.meetings]
      const foundIndex = this.meetings.findIndex(x => x.id === payload.id)

      meetings[foundIndex] = payload
      this.meetings = meetings
    },
  },
}
</script>
