var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"overflow-hidden my-5"},[_c('v-card-title',{staticClass:"d-flex justify-space-between"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('toggleShow', 'documents')}}},[_c('v-icon',[_vm._v(_vm._s(_vm.show ? _vm.icons.mdiChevronUp : _vm.icons.mdiChevronDown))])],1),_c('h4',[_vm._v(" Documents à génerer ")]),_c('v-icon',{attrs:{"top":"","right":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiFile)+" ")])],1),_c('v-divider'),_c('v-expand-transition',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.show),expression:"show"}]},[_c('v-list',{attrs:{"two-line":""}},[_vm._l((_vm.items),function(item,index){return [_c('v-list-item',{key:item.title},[[_c('v-list-item-icon',[(item.generated)?_c('v-icon',{attrs:{"disabled":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiCheckboxMarkedOutline)+" ")]):_c('v-icon',{attrs:{"disabled":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiCheckboxBlankOutline)+" ")])],1),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.title)}}),_c('v-list-item-subtitle',{staticClass:"text--primary",domProps:{"textContent":_vm._s(item.headline)}})],1),_c('v-list-item-action',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-4",attrs:{"color":"primary","disabled":item.title === 'Formulaire de satisfaction' && (!_vm.student.review)},on:{"click":function($event){return _vm.solveFile(item)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiRefresh)+" ")])]}}],null,true)},[_c('span',[_vm._v("Générer")])])],1),_c('v-list-item-action',[(item.generated)?_c('a',{on:{"click":function($event){return _vm.linkToFile(item)}}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"disabled":!item.generated,"color":"primary"}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiDownload)+" ")])]}}],null,true)},[_c('span',[_vm._v("Télécharger")])])],1):_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"disabled":!item.generated,"color":"primary"}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiDownload)+" ")])]}}],null,true)},[_c('span',[_vm._v("Télécharger")])])],1)]),_c('v-list-item-action',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","disabled":!item.generated},on:{"click":function($event){return _vm.showFile(item)}}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiEyeOutline)+" ")])]}}],null,true)},[_c('span',[_vm._v("Visionner")])])],1)]],2),(index < _vm.items.length - 1)?_c('v-divider',{key:index}):_vm._e()]})],2),_c('StudentInvoiceDialogVue',{attrs:{"student":_vm.student,"dialog":_vm.factureDialog},on:{"closeModal":function($event){_vm.factureDialog = false},"invoiceGenerated":_vm.updateInvoice}}),_c('StudentConventionDialogVue',{attrs:{"student":_vm.student,"dialog":_vm.conventionDialog},on:{"closeModal":function($event){_vm.conventionDialog = false},"conventionGenerated":_vm.updateConvention}}),_c('StudentDocsuiviDialogVue',{attrs:{"student":_vm.student,"dialog":_vm.docSuiviDialog},on:{"closeModal":function($event){_vm.docSuiviDialog = false},"docSuiviGenerated":_vm.updateDocsuivi}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }