<template>
  <v-card class="overflow-hidden">
    <v-card-title class="d-flex justify-space-between">
      <v-btn
        icon
        @click="$emit('toggleShow', 'info')"
      >
        <v-icon>{{ show ? icons.mdiChevronUp : icons.mdiChevronDown }}</v-icon>
      </v-btn>
      <h4>
        Informations Personnelles
      </h4>
      <v-icon
        top
        right
      >
        {{ icons.mdiAccountDetails }}
      </v-icon>
    </v-card-title>
    <v-expand-transition>
      <div v-show="show">
        <v-divider></v-divider>
        <h2 class="text-center my-4">
          {{ student.nom }}  {{ student.prenom }}
        </h2>
        <v-card-text>
          <div class="grey--text">
            <v-select
              v-model="student.status"
              :items="statusSwitcher"
              label="Status du stagiaire"
              @change="updateStatus"
            ></v-select>
          </div>
        </v-card-text>
        <template v-if="student.infos">
          <v-divider>
          </v-divider>
          <v-card-text>
            {{ student.infos }}
          </v-card-text>
        </template>
        <v-divider>
        </v-divider>
        <v-card-text>
          <v-row>
            <v-col
              cols="4"
              class="d-flex justify-center"
            >
              <v-btn
                :href="`tel:${student.tel}`"
                block
                color="primary"
              >
                Appeler
              </v-btn>
            </v-col>
            <v-col cols="8">
              <v-text-field
                v-model="student.tel"
                :prepend-icon="icons.mdiPhoneOutline"
                readonly
                dense
                :append-icon="icons.mdiClipboard"
                @click:append="copyClip(student.tel)"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text>
          <v-row>
            <v-col
              cols="4"
              class="d-flex justify-center"
            >
              <v-btn
                color="primary"
                block
                @click="newEmailDialog = true"
              >
                Nouvel e-mail
              </v-btn>
            </v-col>
            <v-col
              cols="8"
            >
              <v-text-field
                v-model="student.email"
                :prepend-icon="icons.mdiAt"
                readonly
                dense
                :append-icon="icons.mdiClipboard"
                @click:append="copyClip(student.email)"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text>
          <v-text-field
            v-model="niceNaissance"
            :prepend-icon="icons.mdiCalendarAccount"
            readonly
            dense
            :append-icon="icons.mdiClipboard"
            @click:append="copyClip(student.naissance)"
          >
          </v-text-field>
        </v-card-text>
        <v-divider>
        </v-divider>
        <!-- ADRESSE -->
        <v-card-text class="mt-2">
          <v-text-field
            v-model="student.adresse.ligne1"
            label="Adresse"
            readonly
            dense
          >
          </v-text-field>
          <v-text-field
            v-if="student.adresse.ligne2.lenght > 0"
            v-model="student.adresse.ligne2"
            label="Adresse"
            readonly
            dense
          >
          </v-text-field>
          <v-row>
            <v-col>
              <v-text-field
                v-model="student.adresse.postcode"
                label="Code postal"
                readonly
                dense
              >
              </v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="student.adresse.ville"
                label="Ville"
                readonly
                dense
              >
              </v-text-field>
            </v-col>
          </v-row>
          <p>
            Nationalité : {{ student.nationalite }}
          </p>
        </v-card-text>
        <v-card-text v-if="student.statutPro">
          <v-text-field
            v-model="student.statutPro"
            readonly
            label="Statut Professionnel"
            dense
          >
          </v-text-field>
        </v-card-text>
        <v-card-text v-if="student.etude">
          <v-text-field
            v-model="student.etude"
            readonly
            label="Niveau d'étude"
            dense
          >
          </v-text-field>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            text
            class="white--text"
            color="primary"
            label
            @click="$router.push({ name: 'student-edit', params: { id: student.id} })"
          >
            Modifier le stagiaire
          </v-btn>
        </v-card-actions>
      </div>
    </v-expand-transition>
    <new-email-dialog
      :dialog="newEmailDialog"
      :student="student"
      @closeDialog="newEmailDialog = false"
    ></new-email-dialog>
  </v-card>
</template>

<script>
import {
  mdiClipboard, mdiPhoneOutline, mdiCalendarAccount, mdiAt,
  mdiChevronDown, mdiChevronUp, mdiAccountDetails,
} from '@mdi/js'
import NewEmailDialog from '@/components/NewEmailDialog.vue'

export default {
  components: {
    NewEmailDialog,
  },
  props: {
    student: {
      type: Object,
      default: () => {},
    },
    show: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      newEmailDialog: false,
      niceNaissance: new Date(),
      statusSwitcher: [
        {
          value: 'avantFormation',
          text: 'Avant formation',
        },
        {
          value: 'enFormation',
          text: 'En formation',
        },
        {
          value: 'inscritCertif',
          text: 'Inscrit à la certification',
        },
        {
          value: 'certifPassee',
          text: 'Certification passée',
        },
        {
          value: 'sortieFormation',
          text: 'Sorti de formation',
        },
        {
          value: 'autre',
          text: 'Autre',
        },
      ],
      icons: {
        mdiClipboard,
        mdiPhoneOutline,
        mdiAt,
        mdiCalendarAccount,
        mdiChevronDown,
        mdiChevronUp,
        mdiAccountDetails,
      },
    }
  },
  mounted() {
    this.niceNaissance = this.reworkNaissance(this.student.naissance)
  },
  methods: {
    reworkNaissance(value) {
      return this.$date(new Date(value), 'dd/MM/yyyy')
    },
    copyClip(e) {
      navigator.clipboard.writeText(e)
        .then(() => {
          this.$store.commit('snackMe', {
            color: 'info',
            text: 'Copié dans le presse-papier',
            value: true,
          })

          return true
        })
        .catch(err => {
          this.$store.commit('snackMe', {
            color: 'error',
            text: 'Erreur lors de la copie',
            value: true,
          })
          console.log(err)
        })

      return false
    },
    updateStatus(e) {
      this.$http.put(`${process.env.VUE_APP_API_URL}/students/${this.student.id}`, { status: e })
        .then(response => {
          this.student.status = response.data.status
          this.$store.commit('snackMe', {
            color: 'success',
            text: 'Status changé avec succès.',
            value: true,
          })
        })
        .catch(error => {
          this.$store.commit('snackMe', {
            color: 'error',
            text: 'Erreur lors de la mise à jour du status',
            value: true,
          })
          console.log(error)
        })
    },
  },
}
</script>

<style>

</style>
